import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import {useStaticQuery, graphql} from 'gatsby'
import Image from '../../../components/image'
import HeroArea from '../../layout/hero-area'
import {ContactBannerWrap} from './banner-area.stc'

const BannerArea = () => {
    const data = useStaticQuery(graphql `
        query ContactBannerQuery {
            contactJson(id: {eq: "contact-hero-content"}) {
                image {
                    childImageSharp {
                        fluid(maxWidth: 1920, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }      
    `);
    const heroImage = data.contactJson.image;
    return (
        <ContactBannerWrap>
            <HeroArea image={heroImage} preheading="Let's get" heading="Connected"/>
        </ContactBannerWrap>
    )
}

export default BannerArea
