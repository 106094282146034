import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useStaticQuery, graphql } from 'gatsby'
import SectionTitle from '../../../components/shared/section-title'
import Text from '../../../components/shared/text'
import Heading from '../../../components/shared/heading'
import ContactForm from '../../../components/forms/contact-form'
import { ContactFormArea } from './form-area.stc'

const FormArea = ({ sectionTitleCSS }) => {
    const ContactData = useStaticQuery(graphql`
        query ContactContentQuery {
            contactJson(id: {eq: "contact-page-content"}) {
                title,
                content
            }
            site {
                siteMetadata {
                  contact_form_post_url
                }
            }
        }
    `)
    const { contactJson: { title, content }, site: { siteMetadata: { contact_form_post_url } } } = ContactData;

    return (
        <ContactFormArea>
            <Container>
                <Row className="justify-content-center">
                    <Col>
                        {title && (
                            <Heading as="h4" mb="20px">{title}</Heading>
                        )}

                        {content && (
                            <Text mb="40px">{content}</Text>
                        )}
                        
                        <ContactForm url={contact_form_post_url} />
                    </Col>
                </Row>
            </Container>
        </ContactFormArea>
    )
}

FormArea.defaultProps = {
    sectionTitleCSS: {
        mb: '40px'
    }
}

export default FormArea
