import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image'
import { Container } from 'reactstrap';
import BannerShape from '../../../assets/img/home-one-banner-shape.png'
import {device} from '../../../theme'

export const HeroWrapper = styled.section `
    height: 300px;
    position: relative;
    margin-bottom: 50px;
    ${device.xsmall}{
        height: 300px;
    }
    ${device.medium}{
        height: 400px;
    }
    ${device.large}{
        height: 500px;
    }
    &:before{
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .row, .col {
        position: initial
    }
`;

export const HeroBG = styled(BackgroundImage) `
    position: absolute !important;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
`;

export const HeroTextBox = styled.div `
    position: absolute;
    bottom: 52px;
    text-shadow: 2px 3px 6px rgba(0, 0, 0, 0.8);

    ${device.small}{
        bottom: 56px;
    }
    ${device.medium}{
        bottom: 106px;
    }
    * {
        position: relative;
        z-index: 9;
    }
`; 