import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'reactstrap';
import { useStaticQuery, graphql } from 'gatsby'
import Heading from '../../../components/shared/heading'
import Text from '../../../components/shared/text'
import { HeroWrapper, HeroBG, HeroTextBox } from './hero-area.stc'

const PortfolioHeroArea = (props) => {
    const {image, heading, preheading, headingStyle, textStyle, textHeadingStyle } = props;

    return (
        <HeroWrapper>
            <HeroBG fluid={image.childImageSharp.fluid} />
            <Container>
                <Row>
                    <Col>
                        <HeroTextBox>
                            {preheading && <Text {...textHeadingStyle} {...textStyle}>{preheading}</Text>}
                            {heading && <Heading {...textHeadingStyle} {...headingStyle}>{heading}</Heading>}
                        </HeroTextBox>
                    </Col>
                </Row>
            </Container>
        </HeroWrapper>
    )
}

PortfolioHeroArea.propTypes = {
    headingStyle: PropTypes.object,
    textStyle: PropTypes.object,
    textHeadingStyle: PropTypes.object
}

PortfolioHeroArea.defaultProps = {
    textStyle: {        
        fontSize: ['42px', '48px', '48px', '64px'],
        textTransform: 'uppercase',
        fontFamily: 'montserrat',
        fontWeight: '300'
    },
    headingStyle: {
        as: 'h1',
        fontSize: ['42px', '48px', '48px', '64px'],
        textTransform: 'uppercase',
        fontFamily: 'montserrat',
        fontWeight: '500'
    },
    textHeadingStyle: {
        color: '#fff',
        lineHeight: 1,
        fontWeight: 'regular'
    }
}

export default PortfolioHeroArea
